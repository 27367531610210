import React, {Component} from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="p-4">
          <div className="credits">
            <div className="py-2">
              { this.props.lang.credits.description }
            </div>
            <img alt="ame-logo" src={ this.props.lang.credits.logo } />
            <div className="other-info py-4">
              {
                Array.isArray(this.props.lang.credits.text) ?
                  this.props.lang.credits.text.map(txt => {

                    let content = (txt.link) ? (<a target={txt.target} href={txt.link}>{txt.text}</a>) : (txt.text)
                    return (
                      <div key={txt.id} className={txt.class}>
                        {content}
                      </div>
                    )
                  }) : ''
              }
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
