import React, { Component } from 'react';
import './Splash.css';
import { Container, Row, Col } from 'reactstrap';
import SectionClaim from '../SectionClaim/SectionClaim.js';

class Splash extends Component {
  constructor(props){
    super(props)

    // questo non mi piace, non è responsive
    this.style = {
      height: (window.innerWidth <= 991.98) ? 'auto' : window.innerHeight,
    }
  }

  render() {
    return (
      <div>
        <Container fluid={true}>
          <Row style={this.style}>
            <Col xs="12" lg="4" className="py-5">
              <SectionClaim lang={this.props.lang} />
            </Col>
            <Col xs="12" lg="8" className="pt-3 pb-5 pt-lg-5 pb-lg-5">
              <div className="d-flex align-items-center h-100">
                <img className="fill-img d-none d-lg-block" alt="preview-site" src={this.props.lang.image} />
                <img className="fill-img d-block d-lg-none" alt="preview-site" src={this.props.lang.image_mobile} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Splash;
