import React, { Component } from 'react';
import Splash from '../Splash/Splash.js';
import SectionText from '../SectionText/SectionText.js';
import SectionImageText from '../SectionImageText/SectionImageText.js';
import SectionRevolution from '../SectionRevolution/SectionRevolution.js';
import SectionResponsive from '../SectionResponsive/SectionResponsive.js';
import SectionContactUs from '../SectionContactUs/SectionContactUs.js';
import SectionPlans from '../SectionPlans/SectionPlans.js';
import Footer from '../Footer/Footer.js';
import './App.css';

import Lang from  '../_config/lang.json';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Splash lang={Lang.claim} />
        <SectionText lang={Lang.opening} />
        {
          Lang.textImages.map((textImage)=>{
            return <SectionImageText key={textImage.id} lang={textImage} />
          })
        }
        <SectionResponsive lang={Lang.responsive} />
        {/*<SectionVisibility lang={Lang.visibility} />*/}
        <SectionRevolution lang={Lang.revolution} />
        <SectionPlans lang={Lang.plans} />
        <SectionContactUs lang={Lang.contactUs}/>
        <Footer lang={Lang.footer}></Footer>
      </div>
    );
  }
}

export default App;
