import React, {Component} from 'react';

class CheckBox extends Component {
	handleInputChange(event){
		this.props.onChange({
			name: event.target.name,
			value: event.target.checked
		})
	}
	render() {
		return(
			<input
				type="checkbox"
				className="form-check-input"
				name={this.props.name}
				id={this.props.name}
				{...this.props.options}
				onChange={this.handleInputChange.bind(this)} />
		);
	}
}

export default CheckBox;
