import React, {Component} from 'react';

class TextArea extends Component {
  handleInputChange(event){
    this.props.onChange({
			name: event.target.name,
			value: event.target.value
		})
  }
  render(){
    return(
      <textarea
        className="form-control"
        id={this.props.name}
        {...this.props.options}
        onChange={this.handleInputChange.bind(this)} />
    )
  }
};

export default TextArea;
