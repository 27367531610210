import React, { Component } from 'react';
import './SectionImageText.css';
import { Container, Row, Col } from 'reactstrap';
import renderHTML from 'react-render-html';

class SectionImageText extends Component {
    render() {
        return (
			<section id={this.props.lang.anchor} className='section-image-text'>
				<Container className='full-height'>
					<Row className={this.props.lang.rowClasses}>
						<Col xs="12" lg="6">
							<h2 className="">{this.props.lang.title}</h2>
							<p className="text">
								{renderHTML(this.props.lang.text)}
							</p>
							<p className='mt-4'>{this.props.lang.cta !== undefined ? <a className='btn btn btn-primary' target={this.props.lang.cta.target} href={this.props.lang.cta.link}>{this.props.lang.cta.text}</a> : ''}</p>
						</Col>
						<Col xs="12" lg="6">
							<img className="img-fluid m-card" alt="unsplash" src={this.props.lang.image} />
						</Col>
					</Row>
				</Container>
			</section>
		)
    }
}

export default SectionImageText;
