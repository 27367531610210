import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import renderHTML from 'react-render-html';
import './MyModal.css';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const CustomType = this.props.opt.activateType || 'div'

    return (
      <span>
        <CustomType color="danger" onClick={this.toggle} className={this.props.opt.activateClass || ''}>
          {this.props.opt.activateText}
        </CustomType>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.opt.class}>
          <ModalHeader toggle={this.toggle}>{this.props.opt.title}</ModalHeader>
          <ModalBody>
            {renderHTML(this.props.opt.text)}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>{this.props.opt.btnTextClose}</Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

export default MyModal;
