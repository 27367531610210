import React, { Component } from 'react';
import './Plan.css';
import renderHTML from 'react-render-html';

class Plan extends Component {
  render() {

    const sale = this.props.lang.price.sale || ''
    const isSale = sale !== '' ? 'on-sale' : ''

    return (
      <div className={`card my-4 ${this.props.lang.classes}`}>
        <div className="card-body">
          <h5 className="card-title">
            <strong>
							{this.props.lang.title}
            </strong>
          </h5>
          <div className="card-text">
            <p>{renderHTML(this.props.lang.description)}</p>
            <ul>
              {
                this.props.lang.options.map(obj => {
                  return (
                    <li className={`py-1 ${obj.classes}`} key={obj.id}>
                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"/>
                      </svg>
                      <span className="px-2">
                        { obj.text }
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="card-footer">
          <div className="">
            <h4 className="d-inline-block">
              <span className={isSale}>
                {this.props.lang.price.value}
              </span>
              {sale}
              {this.props.lang.price.currency}
            </h4>
            <span className="px-2">
              {this.props.lang.price.text}
            </span>
          </div>
          <div className="">
            {this.props.lang.otherPrices.map(price => {
              return (
                <div key={price.id}>

                  { price.value }
                  { price.currency }

                  { price.abbonamento }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Plan;
