import React, {Component} from 'react';
import ListPhotos from '../ListPhotos/ListPhotos.js'
import {Container, Row, Col} from 'reactstrap';

import './SectionRevolution.css'

class SectionRevolution extends Component {
	render() {
		return (
			<section id={this.props.lang.anchor} className='section-revolution'>
				<Container>
					<Row>
						<Col>
							<h2>{this.props.lang.title}</h2>
							<div>
								<p className="description text-center">
									{this.props.lang.description}
								</p>
							</div>
							<Container>
								<ListPhotos gallery={this.props.lang.gallery}/>
							</Container>
						</Col>
					</Row>
				</Container>
			</section>
		)
	}
}

export default SectionRevolution;
