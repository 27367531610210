import React from 'react';
import MyModal from '../MyModal/MyModal.js'

const Label = (props) => {
		return (
			<label
				className="form-check-label" 
				htmlFor={props.name}>
				{
					Array.isArray(props.title) ?
						props.title.map(lbl => {
							let content = lbl.text
							if(lbl.modal)
								content = <MyModal opt={props.modal} />
							if(lbl.link)
								content = <a href={lbl.link}>{content}</a>
							return <span key={lbl.id} className={lbl.class}>{content}</span>
						}) : props.title
				}
			</label>
		)
}

export default Label;
