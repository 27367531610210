import React, { Component } from 'react';
import Plan from '../Plan/Plan.js';
import './SectionPlans.css';
import { Container, Row, Col } from 'reactstrap';

class SectionPlans extends Component {
  render() {
    return (
      <section id={this.props.lang.anchor} className='section-plans'>
        <Container>
          <Row>
            <Col xs="12">
              <h2 className="text-center">{this.props.lang.title}</h2>
              <p className="text">{this.props.lang.text}</p>
            </Col>
            <div className="card-deck">
              {
                this.props.lang.plans.map(obj=>{
                  return (
                    <Plan key={obj.id} lang={obj}></Plan>
                  )
                })
              }
            </div>
          </Row>
          <Row>
            <Col xs="12">
              *iva esclusa
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default SectionPlans;
